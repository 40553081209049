<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <div class="content-box">
        <div class="title">{{$t('payment_page.pay_method')}}</div>
        <div class="line"></div>
        <div class="pay-box">
          <div class="pay-left">
            <img v-if="this.$i18n.locale === 'zh'"
              src="https://www.achencybe.com/img/pc/order/zhifubao_btn.png"
              alt=""
              @click="toPay('1')"
            />
            <br v-if="this.$i18n.locale === 'zh'"/>
            <img
                src="https://www.achencybe.com/img/pc/order/paypal.png"
                alt=""
                @click="createOrderToPay"
            />
            <br />
            <img v-if="this.$i18n.locale === 'zh'"
              src="https://www.achencybe.com/img/pc/order/weixin_btn.png"
              alt=""
              @click="toPay('0')"
            />
            <div v-if="this.$i18n.locale === 'zh'">
              {{$t('payment_page.total_price')}}RMB {{ total }} <br />
              <span>{{$t('payment_page.order_number')}}{{ orderId }}</span>
            </div>
            <div v-else>
              {{$t('payment_page.total_price')}}$ {{ total }} <br />
              <span>{{$t('payment_page.order_number')}}{{ orderId }}</span>
            </div>
          </div>
          <div>
            <img :src="payCode" style="width:400px">
          </div>
        </div>
        <div class="line"></div>
        <div class="title">{{$t('payment_page.problem')}}</div>
        <div class="code-box">
          <div class="code-left">
            <img src="https://www.achencybe.com/img/pc/order/pay-bottom.png" />
            <div>{{ $t('payment_page.scan') }}</div>
          </div>
          <div class="code-right">
            <img
              src="https://www.achencybe.com/img/pc/order/rCode.jpg"
              alt=""
              style="width: 280px"
            />
          </div>
        </div>
      </div>
      <zui-footer />
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";

export default {
  components: { Page, ZuiHeader, ZuiFooter },
  data() {
    return {
      isRim: false,
      orderId: "",
      cardInfo: {},
      shopCar: [],
      wxCode: null,
      wxOrderId: null,
      zfbCode: null,
      zfbOrderId: null,
      payType: null,
      timer1: null,
      timer2: null,
      isPay: false,
    };
  },
  computed: {
    total() {
      let total = 0;
      if (this.$route.query.total) {
        total = this.$route.query.total;
      } else if (this.shopCar.length) {
        this.shopCar.forEach((item) => {
          if(this.isRim){
            if (item.active) {
              let price = this.$i18n.locale === 'zh' ? item.price : item.price_dollar;
              if(price == null || price === 'null'){
                price = "0"
              }
              total += price * item.num;
            }
            let innerTotal = 0;
            if(item.innerList){
              item.innerList.forEach((attrInner) => {
                if (attrInner.active) {
                  let price = this.$i18n.locale === 'zh' ? attrInner.price : attrInner.price_dollar;
                  if(price == null || price === 'null'){
                    price = "0"
                  }
                  innerTotal += price * attrInner.num;
                }
              });
              total += innerTotal;
            }
          } else {
            if (item.active) {
              let price = this.$i18n.locale === 'zh' ? item.price : item.price_dollar;
              if(price == null || price === 'null'){
                price = "0"
              }
              total += price * item.num;
            }
          }

        });
      }
      return total.toString().replace(/\d+/, function(n) {
        return n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      });
    },
    payCode() {
      if (this.payType == "1") {
        return this.zfbCode;
      } else if (this.payType == "0") {
        return this.wxCode;
      } else {
        return "https://www.achencybe.com/img/pc/order/pay_default.jpg";
      }
    }
  },
  mounted() {
    if(this.$route.query.cartype === 'rim'){
      this.isRim = true
    }
    if (this.$route.query.order) {
      this.orderId = this.$route.query.order;
      this.createCode();
    } else if (window.localStorage.getItem("shopCar")) {
      this.shopCar = JSON.parse(window.localStorage.getItem("shopCar"));
      this.createOrder();
    } else {
      alert("未查询到购买商品");
    }
  },
  methods: {
    createOrder() {
      this.$api.addOrderNumber().then((res) => {
        if (res.code === 0) {
          this.orderId = res.msg;
          this.getCarType();
        }
      });
    },
    getCarType() {
      const val = this.$route.query.cartype
        ? this.$route.query.cartype
        : "carlistM3";
      let data = {
        carModelName:
          val === "carlistM3"
            ? "M3/M4"
            : val === "carlistBMW3"
              ? "The 3"
              : val === 'carlistchallenger'
                ? "Challgner SRT" : val=== "carlistTHE4"
                  ? "THE 4" : val === "carlistTHE5" ? "THE 5" : '锻造轮辋' // 'rim'
      };
      this.$api.getCarDetail(data).then((res) => {
        if (res.code === 0) {
          this.cardInfo = res.data;
          this.saveOrder();
        }
      });
    },
    getOrderGoodsMountings() {
      let lst = this.shopCar
          .filter((item) => {
            return item.active;
          })
          .map((item) => {
            return {
              accessoriesListId: item.id,
              partsName: item.title,
              partsPrice: item.partsPrice,
              partsNumber: item.num
            };
          })
      this.shopCar.forEach((item) => {
        if (item.innerList) {
          let lstInner = item.innerList
              .filter((inner) => {return inner.active})
              .map((itemInner) => {
            return {
              accessoriesListId: itemInner.id,
              partsName: itemInner.title,
              partsPrice: itemInner.partsPrice,
              partsNumber: itemInner.num
            };
          })
          if (lstInner.length > 0) {
            lst = lst.concat(lstInner)
          }
        }
      });
      console.log(lst)
      return lst
    },
    saveOrder() {
      const userInfo = this.$store.state.userInfo;
      let data = {
        orderNumber: this.orderId,
        orderType: this.cardInfo.isPresell,
        clientId: userInfo.id,
        completionTime:
          this.cardInfo.isPresell == "1"
            ? this.cardInfo.releaseTime
            : this.cardInfo.completionTime,
        listOrderGoodsMountings: this.getOrderGoodsMountings()
      };
      if (window.localStorage.getItem("facilitatorId")) {
        data.shippingMethod = "配送至服务商";
        data.facilitatorId = window.localStorage.getItem("facilitatorId");
      } else {
        data.shippingMethod = "为我送货";
        data.clientName = userInfo.deliveryName;
        data.city = userInfo.deliveryCityName;
        data.address = userInfo.deliveryAddress;
        data.recipientPhone = userInfo.deliveryMobile;
      }

      if (this.cardInfo.isPresell == "1") {
        let total = 0;
        if (this.shopCar.length) {
          this.shopCar.forEach((item) => {
            if (item.active) {
              total += item.price * item.num;
            }
          });
        }
        data.advanceDeposit = total;
        data.dilatationCoefficient = this.cardInfo.dilatationCoefficient;
        data.orderTotalAmount = total;
      } else {
        let total = 0;
        if (this.shopCar.length) {
          this.shopCar.forEach((item) => {
            if (item.active) {
              total += item.partsPrice * item.num;
            }
          });
        }
        data.orderTotalAmount = total;
      }
      this.$api.saveOrder(data).then((res) => {
        if (res.code === 0) {
          this.createCode();
        }
      });
    },
    createCode() {
      const total = parseFloat(this.total.replace(RegExp(",","g"),""));
      this.$api.getWXPayCode({
        total_fee: total,
        order_id: this.orderId
      }).then(res => {
        if (res.code === 0) {
          this.wxOrderId = res.data.outTradeNo;
          this.wxCode = res.data.wxPayQrCode;
        }
      });
      this.$api.getZFBPayCode({
        total_fee: total,
        order_id: this.orderId
      }).then(res => {
        if (res.code === 0) {
          this.zfbOrderId = res.data.outTradeNo;
          this.zfbCode = res.data.aliPayQrCode;
        }
      });
      this.timer1 = setTimeout(() => {
        clearTimeout(this.timer1);
        this.createCode();
      }, (5 * 60 * 1000));
      this.getPayState();
    },
    toPay(type) {
      this.payType = type;
    },
    getPayState() {
      this.timer2 = setInterval(() => {
        if (!this.wxOrderId && !this.zfbOrderId) {
          return;
        }
        let data = {
          orderId: this.orderId,
          refId: this.wxOrderId + "," + this.zfbOrderId
        };
        this.$api.getPayState(data).then(res => {
          if (res.code === 0 && res.data == "1") {
            clearTimeout(this.timer1);
            clearInterval(this.timer2);
            this.$router.push({ name: "payResult" });
          }
        });
      }, 1000);
    },

    createOrderToPay(){
      if(this.isPay)return;
      this.isPay = true;
      const cartype = this.$route.query.cartype
          ? this.$route.query.cartype
          : "carlistM3";
      window.open(`${process.env.VUE_APP_BASE_API}/payPal/createOrder?deviceType=pc&amountMoney=${this.total}&orderNumber=${this.orderId}&cartype=${cartype}`, '_self');
      this.isPay = false;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer1);
    clearInterval(this.timer2);
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/PaymentPage.scss";
</style>
