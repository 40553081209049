import { render, staticRenderFns } from "./PaymentPage.vue?vue&type=template&id=6ff4cf98&scoped=true&"
import script from "./PaymentPage.vue?vue&type=script&lang=js&"
export * from "./PaymentPage.vue?vue&type=script&lang=js&"
import style0 from "./PaymentPage.vue?vue&type=style&index=0&id=6ff4cf98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff4cf98",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/matengyu/个人/bwm/CarModifyWeb/sellgit/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ff4cf98')) {
      api.createRecord('6ff4cf98', component.options)
    } else {
      api.reload('6ff4cf98', component.options)
    }
    module.hot.accept("./PaymentPage.vue?vue&type=template&id=6ff4cf98&scoped=true&", function () {
      api.rerender('6ff4cf98', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/public/PaymentPage.vue"
export default component.exports