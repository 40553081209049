var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "content-box" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("payment_page.pay_method"))),
          ]),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "pay-box" }, [
            _c("div", { staticClass: "pay-left" }, [
              this.$i18n.locale === "zh"
                ? _c("img", {
                    attrs: {
                      src: "https://www.achencybe.com/img/pc/order/zhifubao_btn.png",
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toPay("1")
                      },
                    },
                  })
                : _vm._e(),
              this.$i18n.locale === "zh" ? _c("br") : _vm._e(),
              _c("img", {
                attrs: {
                  src: "https://www.achencybe.com/img/pc/order/paypal.png",
                  alt: "",
                },
                on: { click: _vm.createOrderToPay },
              }),
              _c("br"),
              this.$i18n.locale === "zh"
                ? _c("img", {
                    attrs: {
                      src: "https://www.achencybe.com/img/pc/order/weixin_btn.png",
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toPay("0")
                      },
                    },
                  })
                : _vm._e(),
              this.$i18n.locale === "zh"
                ? _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("payment_page.total_price")) +
                        "RMB " +
                        _vm._s(_vm.total) +
                        " "
                    ),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("payment_page.order_number")) +
                          _vm._s(_vm.orderId)
                      ),
                    ]),
                  ])
                : _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("payment_page.total_price")) +
                        "$ " +
                        _vm._s(_vm.total) +
                        " "
                    ),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("payment_page.order_number")) +
                          _vm._s(_vm.orderId)
                      ),
                    ]),
                  ]),
            ]),
            _c("div", [
              _c("img", {
                staticStyle: { width: "400px" },
                attrs: { src: _vm.payCode },
              }),
            ]),
          ]),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("payment_page.problem"))),
          ]),
          _c("div", { staticClass: "code-box" }, [
            _c("div", { staticClass: "code-left" }, [
              _c("img", {
                attrs: {
                  src: "https://www.achencybe.com/img/pc/order/pay-bottom.png",
                },
              }),
              _c("div", [_vm._v(_vm._s(_vm.$t("payment_page.scan")))]),
            ]),
            _c("div", { staticClass: "code-right" }, [
              _c("img", {
                staticStyle: { width: "280px" },
                attrs: {
                  src: "https://www.achencybe.com/img/pc/order/rCode.jpg",
                  alt: "",
                },
              }),
            ]),
          ]),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }